.spacer {
  flex: 1;
}

.absolute-hidden {
  position: absolute;
  left: -100vw;
  top: -100vh;
}

._booking_button {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  font-weight: bold;

  img {
    width: 18px;
    height: auto;
    transform: translateY(-8px);
    margin-right: 8px;
  }

  @include tablet-portrait() {
    font-size: 11px;
    line-height: 13px;

    img {
      width: 16px;
      transform: translateY(-7px);
    }
  }
}

.panel {
  position: fixed;
  z-index: 999;
  transform-origin: center;
  // transform: translateX(-100%);
  pointer-events: none;
  backface-visibility: hidden;
  background: $black-panel; // stylelint-disable-line
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  contain: strict;
  transform: translate(-50%, -50%);
}

.clear-margin {
  margin: -1em 0;
}

.reveal-block {
  opacity: 0;
  // transform: translateY(5vh);
  @include transition('opacity, transform, object-fit', .8s, $sine-ease-out);

  .show-page &.revealed {
    opacity: 1;
    // transform: none;
  }
}

.underline-button {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -.05em;
    left: 0;
    width: 100%;
    height: .05em;
    background: var(--text-color);
    transform: scaleX(0);
    transition: transform .25s $quart-ease-in-out;
    transform-origin: right;
    backface-visibility: hidden;
  }

  .desktop &:hover, &.current {
    &::after {
      transform: scaleX(1.001);
      transform-origin: left;
    }
  }

  &.white {
    color: $white;

    &::after {
      background: $white;
    }
  }

  &.inverted {
    &::after {
      transform: scaleX(1.001);
      transform-origin: left;
    }

    .desktop &:hover {
      &::after {
        transform: scaleX(0);
        transform-origin: right;
      }
    }
  }
}

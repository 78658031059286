.footer {
  --text-color: #{$white};

  background-color: $black-text;
  color: $white;

  &__nav {
    display: flex;
    padding: 65px $page-padding-x;
    max-width: $site-max-width;
    box-sizing: content-box;
    margin: auto;
  }

  &__item {
    font-family: $title-font;
    font-size: 15px;
    line-height: 30px;

    & + & {
      margin-left: 40px;
    }
  }

  &__languages {
    @extend .header__languages;
  }

  &__lang {
    @extend .header__lang;
  }

  &__langs {
    background: none !important;
    @extend .header__langs;
  }
}

@include tablet-portrait() {
  .footer {
    &__nav {
      flex-direction: column;
      padding: 60px 0;
    }

    &__no-mobile {
      display: none;
    }

    &__item {
      font-family: $main-font;
      font-size: 16px;
      line-height: 148%;
      text-align: center;
      font-weight: bold;

      & + & {
        margin-left: 0;
        margin-top: 25px;
      }
    }

    &__languages {
      justify-content: center;

      &::after {
        display: none !important;
      }
    }

    &__langs {
      right: 50% !important;
      transform: translateX(50%);
    }
  }
}

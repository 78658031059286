.header {
  max-width: $site-max-width;
  margin: auto;
  position: relative;
  margin-top: $header-padding-top / 2;
  padding: ($header-padding-top / 2) $page-padding-x;
  z-index: 50;
  background: var(--background-color);
  box-sizing: content-box;
  transition: transform .3s $sine-ease-out, background-color .6s $sine-ease-out;

  .shown-page & {
    position: sticky;
    top: -1px;
  }
  // .bar-visible.scroll-down & {
  //   transform: translateY(-100%);
  // }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__logo {
    flex-grow: 1.5;

    h1 {
      display: block;
      text-indent: 99em;
      overflow: hidden;
      white-space: nowrap;
      width: 0;
      height: 0;
    }

    img {
      height: 55px;
      width: 100px;
      object-fit: contain;
      object-position: left center;
    }
  }

  &__link {
    & + & {
      margin-left: 60px;
    }

    a, span {
      font-family: $title-font;
      font-size: 18px;
      line-height: 30px;
    }

    &:first-child {
      flex-grow: 1;

      a {
        font-size: 30px;
      }
    }
  }

  .white-page & &__languages::after {
    background-image: url(/assets/svg/globe.svg);
  }

  &__languages {
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      width: 12px;
      height: 12px;
      background-image: url(/assets/svg/globe-white.svg);
      content: '';
      display: block;
      margin-left: 10px;
    }

    & > span {
      position: relative;
    }

    &:hover {
      .header__langs {
        max-height: 200px;
      }
    }
  }

  &__langs {
    position: absolute;
    top: 100%;
    right: -20px;
    background: var(--background-color);
    max-height: 0;
    overflow: hidden;
    box-sizing: border-box;
    transition: max-height .5s $sine-ease-out;
  }

  &__lang {
    margin: 0 20px;
    display: block;

    &:last-child {
      margin-bottom: 8px;
    }
  }

  &__toggle-menu {
    $positionDuration: .2s;
    $transformDuration: .4s;
    $positionEasing: $quad-ease-in-out;
    $transformEasing: $quad-ease-in-out;

    display: none;
    position: relative;
    width: 18px;
    height: 12px;

    &::after, &::before {
      content: '';
    }

    span {
      display: block;
      white-space: nowrap;
      text-indent: 99em;
      overflow: hidden;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after, &::before, span {
      display: block;
      width: 100%;
      background: var(--text-color);
      height: 1px;
      position: absolute;
      left: 0;
      transition:
        top $positionDuration $positionEasing $transformDuration,
        bottom $positionDuration $positionEasing $transformDuration,
        transform $transformDuration $transformEasing,
        background $header-animation;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    .menu-opened & {
      span, &::before {
        top: 50%;
        transform: translateY(-50%) rotate((180 + 45deg));
      }

      &::after {
        bottom: 50%;
        transform: translateY(50%) rotate((180 + 45 + 90deg));
      }

      &::after, &::before, span {
        background: $white;
        transition:
          top $positionDuration $positionEasing,
          bottom $positionDuration $positionEasing,
          transform $transformDuration $transformEasing $positionDuration,
          background $header-animation;
      }
    }
  }
}

@include tablet-portrait() {
  .header {
    padding: $header-padding-top-m $page-padding-x-m;
    margin-top: 0;

    &.menu-opened & {
      &__link {
        color: $white;
      }
    }

    &__inner {
      justify-content: space-between;
      position: relative;
      z-index: 100;
    }

    &__logo {
      display: none;
    }

    &__link {
      transition: color $header-animation;

      &:not(.header__mobile) {
        display: none;
      }

      .underline-button.inverted {
        &::after {
          display: none;
        }
      }

      a {
        font-size: 14px;
        line-height: 15px;
      }

      &:first-child a {
        font-size: 18px;
        line-height: 19px;
      }
    }

    &__toggle-menu {
      display: block;
    }
  }
}

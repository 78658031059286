.page {
  opacity: 0;

  &__content {
    padding: #{$page-padding-top - ($header-padding-top / 2)} $page-padding-x 80px;
    max-width: $site-max-width;
    box-sizing: content-box;
    margin: auto;

    .underline-button {
      font-weight: bold;
    }
  }

  &__module {
    display: flex;
    justify-content: space-between;
    margin-bottom: 170px;

    @include tablet-portrait () {
      flex-direction: column;
      margin-bottom: 40px;
    }
  }

  &__title {
    font-size: 66px;
    line-height: 69px;
    margin-bottom: 70px;

    @include tablet-portrait () {
      font-size: 33px;
      line-height: 35px;
      margin-bottom: 30px;
    }
  }

  &__description {
    font-size: 17px;
    line-height: 28px;

    @include tablet-portrait () {
      font-size: 16px;
      line-height: 24px;
    }

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__column {
    flex-basis: $column1-percent;

    @include tablet-portrait () {
      flex-basis: auto;
    }

    &:last-child {
      flex-basis: $column2-percent;

      @include tablet-portrait () {
        flex-basis: auto;
        margin-top: 20px;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

@include tablet-portrait () {
  .page {
    &__content {
      padding: $page-padding-top-m $page-padding-x-m 0;
    }
  }
}

@font-face {
  font-family: $main-fontname;
  src: url(/assets/fonts/Gill-Sans.woff2) format('woff2'),
    url(/assets/fonts/Gill-Sans.woff) format('woff');
  font-weight: bold;
}

@font-face {
  font-family: $main-fontname;
  src: url(/assets/fonts/Gill-Sans-Light.woff2) format('woff2'),
    url(/assets/fonts/Gill-Sans-Light.woff) format('woff');
  font-weight: normal;
}

@font-face {
  font-family: $title-fontname;
  src: url(/assets/fonts/Garamond.woff2) format('woff2'),
    url(/assets/fonts/Garamond.woff) format('woff');
  font-weight: normal;
}

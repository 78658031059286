.restaurant-glimpse {
  &__images {
    flex-flow: wrap;
    @extend .page__module;
  }

  &__image {
    width: (710px / $site-max-width) * 100%;
    height: 472px;
    object-fit: cover;
    margin-bottom: 20px;

    &:first-child, &:last-child {
      width: $column1-percent;
    }
  }

  &__title {
    display: block;
    @extend .page__title;
  }
}

@include tablet-portrait () {
  .restaurant-glimpse {
    display: none;
  }
}

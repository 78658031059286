.restaurant-findus {
  flex-flow: wrap;
  margin-bottom: 0 !important;
  @extend .page__module;

  &__title {
    flex-basis: 100%;
    @extend .page__title;
  }

  &__blocks {
    display: flex;
  }

  &__block {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 50px;
    margin-right: 60px;
    flex-basis: 300px;
    flex-shrink: 0;

    &-title {
      font-size: 32px;
      line-height: 34px;
      margin-bottom: 34px;
    }
  }
}

@include tablet-portrait () {
  .restaurant-findus {
    flex-flow: wrap;
    margin-bottom: 80px !important;

    &__title {
      margin-bottom: 50px;
    }

    &__blocks {
      display: flex;
      flex-direction: column;
      flex-basis: auto;
    }

    &__block {
      font-size: 17px;
      line-height: 148%;
      margin-bottom: 40px;
      margin-right: 0;
      flex-basis: auto;

      &-title {
        font-size: 24px;
        line-height: 25px;
        margin-bottom: 25px;
      }

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

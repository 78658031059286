.menu {
  display: none;
  position: fixed;
  z-index: 49;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $white;

  &__background {
    background: $black-text;
    @include fullscreen;
  }

  &__inner.menu__restaurants {
    padding-top: 120px;
    justify-content: flex-start;

    .menu {
      &__link {
        flex-grow: 0;
        margin-bottom: 40px;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 70px;
    padding-bottom: 70px;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  &__logo {
    flex-shrink: 0;
    flex-grow: 1;

    img {
      height: 55px;
      width: 125px;
      object-fit: contain;
      object-position: center;
    }
  }

  &__link {
    font-size: 17px;
    line-height: 148%;
    flex-grow: .4;
    font-weight: bold;
  }

  &__big {
    font-weight: normal;
    font-family: $title-font;
    font-size: 28px;
    line-height: 40px;
    flex-grow: 1;

    // margin-bottom: 4vh;
  }

  &__languages {
    justify-content: center;
    text-align: center;
    @extend .header__languages;

    &::after {
      display: none !important;
    }
  }

  &__lang {
    @extend .header__lang;
  }

  &__langs {
    background: none !important;
    @extend .header__langs;

    right: 50% !important;
    transform: translateX(50%);
  }
}

@include not-tablet-portrait() {
  .menu {
    display: none !important;
  }
}

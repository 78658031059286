.restaurant-event {
  @extend .page__module;

  &__column {
    @extend .page__column;

    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__title {
    margin-bottom: 70px;
    @extend .page__title;
  }

  &__description {
    margin-bottom: 70px;
    @extend .page__description;
  }
}

@include tablet-portrait () {
  .restaurant-event {
    flex-direction: column-reverse;
    margin-bottom: 80px;

    &__column {
      &:last-child {
        margin-top: 0;
        margin-bottom: 40px;
      }
    }

    &__title {
      margin-bottom: 30px;
    }

    &__description {
      margin-bottom: 20px;
    }
  }
}

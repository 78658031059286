.restaurant-focus {
  flex-flow: wrap;
  @extend .page__module;

  &__column {
    @extend .page__column;

    &:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__title {
    flex-shrink: 0;
    flex-basis: 100%;
    margin-bottom: 70px;
    @extend .page__title;
  }

  &__subtitle {
    font-family: $main-font;
    font-weight: bold;
    margin-bottom: 35px;
    @extend .page__description;
  }

  &__description {
    @extend .page__description;
  }
}

@include tablet-portrait () {
  .restaurant-focus {
    flex-flow: wrap;
    margin-bottom: 80px;

    &__title {
      margin-bottom: 30px;
    }

    &__subtitle {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
}

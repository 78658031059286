.restaurant {
  .page__content {
    padding-bottom: 0;
  }

  &__cover {
    position: relative;

    &-img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__booking {
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--background-color);
    padding: 30px 54px 20px;
    @extend ._booking_button;
  }

  &__description {
    margin-top: 74px;
    font-size: 57px;
    line-height: 1.22;
    max-width: 850px;
    margin-bottom: 65px;
  }

  &__mobile-logo {
    display: none;
    max-height: 55px;
    max-width: 125px;
    height: auto;
    width: auto;
    object-fit: contain;
    object-position: center;
    margin: 0 auto 24px;
  }
}

@include tablet-portrait() {
  .restaurant {
    .page__content {
      padding-bottom: 0;
    }

    &__mobile-logo {
      display: block;
    }

    &__cover {
      margin-left: -$page-padding-x-m;
      margin-right: -$page-padding-x-m;

      &-img {
        height: 488px;
        height: calc(95vh - 150px);
        object-fit: cover;
      }
    }

    &__booking {
      padding: 25px 35px 17px;
    }

    &__description {
      margin-top: 60px;
      font-size: 28px;
      line-height: 116%;
      margin-bottom: 50px;
    }
  }
}

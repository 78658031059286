.restaurant-menu {
  flex-flow: wrap;
  @extend .page__module;

  &__column {
    @extend .page__column;
  }

  &__title {
    flex-basis: 100%;
    @extend .page__title;
  }

  &__links {
    margin-top: 40px;
  }

  &__link {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  &__list {
    columns: 2;
    column-gap: 40px;
  }

  &__item {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 50px;

    &-title {
      font-family: $main-font;
      font-size: 16px;
      line-height: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  &__description {
    @extend .page__description;
  }
}

@include tablet-portrait () {
  .restaurant-menu {
    &__list {
      columns: 1;
      margin-top: 20px;
    }

    &__item {
      line-height: 24px;
      margin-bottom: 30px;

      &-title {
        margin-bottom: 10px;
      }
    }
  }
}

.page.group {
  .group {
    &__headline {
      @extend .restaurants__headline;

      margin: 40px 0 60px;
    }
  }

  .group__description {
    font-size: 17px;
    line-height: 1.48;
    max-width: 600px;

    strong {
      font-size: 14px;
      line-height: 16px;
      margin: 60px 0 40px;
      display: block;
    }

    p:first-child, p:last-child {
      margin: 0;
    }
  }
}

@include tablet-portrait() {
  .page.group {
    .group {
      &__headline {
        margin-top: 15px;
        margin-bottom: 20px !important;
      }
    }

    .group__description {
      font-size: 14px;
      line-height: 22px;

      strong {
        font-size: 16px;
        line-height: 18px;
        margin: 30px 0 20px;
      }
    }
  }
}

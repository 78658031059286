.restaurant-intro {
  @extend .page__module;

  &__column {
    @extend .page__column;
  }

  &__location {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    font-family: $main-font;
    margin-bottom: 140px;
  }

  &__title {
    margin: 60px 0 25px;
    font-size: 32px;
    line-height: 34px;
  }

  &__description {
    max-width: $column1-width;
    @extend .page__description;
  }
}

@include tablet-portrait () {
  .restaurant-intro {
    &__location {
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 40px;
    }

    &__title {
      margin: 40px 0 20px;
      font-size: 24px;
      line-height: 25px;
    }
  }
}

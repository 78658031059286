$quad-ease-in: cubic-bezier(.55, .085, .68, .53);
$cubic-ease-in: cubic-bezier(.55, .055, .675, .19);
$quart-ease-in: cubic-bezier(.895, .03, .685, .22);
$quint-ease-in: cubic-bezier(.755, .05, .855, .06);
$sine-ease-in: cubic-bezier(.47, 0, .745, .715);
$expo-ease-in: cubic-bezier(.95, .05, .795, .035);
$circ-ease-in: cubic-bezier(.6, .04, .98, .335);

$quad-ease-out: cubic-bezier(.25, .46, .45, .94);
$cubic-ease-out: cubic-bezier(.215, .61, .355, 1);
$quart-ease-out: cubic-bezier(.165, .84, .44, 1);
$quint-ease-out: cubic-bezier(.23, 1, .32, 1);
$sine-ease-out: cubic-bezier(.39, .575, .565, 1);
$expo-ease-out: cubic-bezier(.19, 1, .22, 1);
$circ-ease-out: cubic-bezier(.075, .82, .165, 1);

$quad-ease-in-out: cubic-bezier(.455, .03, .515, .955);
$cubic-ease-in-out: cubic-bezier(.645, .045, .355, 1);
$quart-ease-in-out: cubic-bezier(.77, 0, .175, 1);
$quint-ease-in-out: cubic-bezier(.86, 0, .07, 1);
$sine-ease-in-out: cubic-bezier(.445, .05, .55, .95);
$expo-ease-in-out: cubic-bezier(1, 0, 0, 1);
$circ-ease-in-out: cubic-bezier(.785, .135, .15, .86);

$header-animation: .3s $quad-ease-out;

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}

@function parralax-depth-correct($translateZ, $perpective : 1px) {
  @return 1 + ($translateZ * -1) / $perpective;
}

@function explode($string,$dem) {
  $list: ();
  $sum: str-length($string);

  @for $i from 1 through $sum {
    $str: str-index($string, $dem);

    @if str-length($string) >= 1 and $str == null {
      $list: append($list, $string);
      $string: '';
    }

    @if type-of($str) == number {
      $each: str-slice($string, 0, ($str - 1));
      $list: append($list, $each);
      $string: str-slice($string, ($str + 1), $sum);
    }
  }

  @return $list;
}

@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);
  background-size: $width $height;
  background-repeat: no-repeat;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
    & {
      background-image: url($file + '@2x.' + $type);
    }
  }
}

@mixin transition($properties :'visibility, opacity', $duration:.5s, $ease:$quad-ease-out, $delay:0s) {
  $value: '';
  $list: explode($properties, ',');

  @each $property in $list {
    @if (value != '') {
      $value: $value +', ';
    }

    $value: $value+'#{$property} #{$duration} #{$ease} #{$delay}';
  }

  transition: #{$value};
}

@mixin on-line-text-no-wrap() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ellipsis($lines-to-show, $font-size, $line-height :1) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  line-height: $line-height;
  max-height: $font-size*$lines-to-show*$line-height;
  font-size: $font-size;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fullscreen( $position: absolute ) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin fullscreen-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fullscreen-cover() {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fullscreen-contain() {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertical-align-children($target : '*') {
  .flexbox & {
    display: flex;
    align-items: center;
  }

  .no-flexbox & > #{$target} {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
  }
}

@mixin center-children($target : '*', $modernizrAddons:'') {
  #{$modernizrAddons}.flexbox & {
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-box-align: center;
  }

  #{$modernizrAddons}.no-flexbox & > #{$target} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin fade-transition($show : true, $selector : '&.show') {
  @include transition;

  @if ($show) {
    opacity: 0;
    visibility: hidden;
  } @else {
    opacity: 1;
    visibility: visible;
  }

  #{$selector} {
    @if ($show) {
      opacity: 1;
      visibility: visible;
    } @else {
      opacity: 0;
      visibility: hidden;
    }
  }
}

@mixin box-shadow($distance :5px, $opacity : .5) {
  box-shadow: 0 0 $distance rgba(0, 0, 0, $opacity);
}

@mixin text-shadow($distance :5px, $color : rgba(0, 0, 0, .5)) {
  text-shadow: 0 0 $distance $color;
}

@mixin filter-shadow($distance : 2px, $opacity : .3) {
  filter: drop-shadow(0 0 $distance rgba(0, 0, 0, $opacity));
}

@mixin css-parralax($translateZ, $perspective: 1px) {
  transform: translateZ($translateZ) scale(parralax-depth-correct($translateZ, $perspective));
}

@mixin justify-one-line() {
  text-align: justify;

  &::after {
    content: '';
    display: inline-block;
    width: 100%;
  }
}

@mixin mix-blend-mode() {
  @supports (mix-blend-mode: exclusion) {
    @content;
  }
}


/* stylelint-disable color-no-hex */

// -----
// fonts
// -----

$main-fontname : 'Gill Sans';
$main-font : $main-fontname, 'Helvetica Neue', 'Arial', sans-serif;

$title-fontname : 'EB Garamond';
$title-font : $title-fontname, 'Helvetica Neue', 'Arial', sans-serif;

// -------
// colours
// -------

$white: #fff;
$black: #000;

$black-panel: #101010;
$black-text: #161616;
$grey-text: #807070;

$footer-bg: $white;

$grey20: #333;
$grey40: #646464;
$grey52: #848484;
$grey59: #979797;
$grey64: #a3a3a3;

// -------
// sizes
// -------

$site-max-width: 1196px;

$page-padding-top: 55px;
$page-padding-x: 50px;

$page-padding-top-m: 15px;
$page-padding-x-m: 30px;

$header-padding-top: 55px;
$header-padding-top-m: 20px;

$column1-width: 466px;
$column1-percent: ($column1-width / $site-max-width) * 100%;
$column2-width: 588px;
$column2-percent: ($column2-width / $site-max-width) * 100%;

// -------
// mixins
// -------

@mixin normal-font-weight() {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
    font-weight: 300;
  }

  font-weight: 400;
}

// -------
// transition
// -------

@mixin basic-transition() {
  @include transition('opacity, color', .2s, $sine-ease-out);
}

// -------
// MQ
// -------

$tablet-max-width: 1024px;
$tablet-portrait-max-width: 900px;
$phone-max-width: 740px;
$phone-portrait-max-width: 420px;

@mixin tablet() {
  @media all and (max-width: #{$tablet-max-width}) {
    @content;
  }
}

@mixin not-tablet() {
  @media all and (min-width: #{$tablet-max-width + 1px}) {
    @content;
  }
}

@mixin tablet-portrait() {
  @media screen and (max-width: #{$tablet-portrait-max-width}) {
    @content;
  }
}

@mixin not-tablet-portrait() {
  @media screen and (min-width: #{$tablet-portrait-max-width + 1px}) {
    @content;
  }
}

@mixin phone() {
  @media screen and (max-width: #{$phone-max-width}) {
    @content;
  }
}

@mixin phone-portrait() {
  @media screen and (max-width: #{$phone-portrait-max-width}) {
    @content;
  }
}

@mixin not-phone() {
  @media screen and (min-width: #{$phone-portrait-max-width + 1}) {
    @content;
  }
}

/* stylelint-enable color-no-hex */


html, body {
  width: 100%;
  height: auto;
  margin: 0;
  font-size: 16px;
}

@include tablet-portrait() {
  html, body {
    font-size: 16px;
  }
}

html {
  height: 100%;
  overflow: visible;
  position: relative;
  font-family: $main_font;
  min-height: 100%;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    pointer-events: none;
  }
}

body {
  --mode: 'dark';
  --text-color: #{$white};
  --background-color: #{$black-text};

  position: relative;
  min-height: 100%;
  min-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  color: var(--text-color);
  background: var(--background-color);
  transition: background-color .6s $sine-ease-out;

  &.white-page {
    --text-color: 'white';
    --text-color: #{$black-text};
    --background-color: #{$white};
  }
}

* {
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: $title_font;
  margin: 0;
}

button, a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

input, button, a {
  &:focus, &:active {
    outline: none;
  }
}

strong {
  font-weight: bold;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

button {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  display: block;
  background: transparent;
  padding: 0;
}

.hidden {
  position: fixed;
  left: -100vh;
  visibility: hidden;
  pointer-events: none;
}

@import 'fonts/fonts';

@import 'ui/misc';
@import 'ui/footer';
@import 'ui/header';
@import 'ui/menu';
@import 'ui/page';

@import 'sections/group';
@import 'sections/restaurant';
@import 'sections/restaurants';

@import 'modules/_intro';
@import 'modules/_menu';
@import 'modules/_event';
@import 'modules/_focus';
@import 'modules/_glimpse';
@import 'modules/findus';

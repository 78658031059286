.restaurants {
  &__headline {
    font-size: 46px;
    line-height: 49px;
    max-width: 650px;
    margin-bottom: 90px;
  }

  $width: (275px / $site-max-width) * 100%;
  $margin: (100% - ($width * 4)) / 3;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &::after {
      content: '';
      min-width: $width;
      height: 1px;
    }
  }

  &__item {
    position: relative;
    width: $width;
    margin-bottom: $margin;

    &-image {
      width: 100%;
      height: auto;
      display: block;
    }

    &-title {
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 30px;
      line-height: 30px;
    }

    &-booking {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 40px;

      @extend ._booking_button;
    }
  }
}

@include tablet-portrait() {
  .restaurants {
    padding-bottom: 1px;

    &__headline {
      font-size: 28px;
      line-height: 116%;
      margin-bottom: 60px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-bottom: $page-padding-x-m;

      &::after {
        display: none;
      }
    }

    &__item {
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin: 0;
      }

      &-title {
        top: 30px;
        font-size: 24px;
        line-height: 25px;
      }

      &-booking {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
      }
    }
  }
}
